/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

const config: {
  apiKey: string;
  baseUrl: string;
  siteUrl: string;
  shopName: string;
  version: string;
  pushNotifications: boolean;
  pushNotificationsColor: string;
} = {
  /// baseUrl: 'https://api.choihainzan.com/index.php?choihainzan_param=',
  /// siteUrl: 'https://beta.choihainzan.com/index.php',
  // apiKey: strd("144e2ebfd3b13d7fa531ee686e93c89a"),
  apiKey: "144e2ebfd3b13d7fa531ee686e93c89a",
  ///baseUrl: "https://api-ch1.yyp.mn/index.php?choihainzan_param=",
  ///siteUrl: "https://api-beta.yyp.mn/",
  // baseUrl: "https://www.choihainzan.com/api2/admin/api/4.0/",
  // baseUrl: "https://api.choihainzan.com/index.php?choihainzan_param=",
  // siteUrl: "https://choihainzan.com/api2/admin/api/4.0/",

  baseUrl: "http://13.229.232.32/apiserver.php?choihainzan_param=",
  siteUrl: "http://13.229.232.32/apiserver.php",

  shopName: "choihainzan.com",
  version: "ULT",
  pushNotifications: true,
  pushNotificationsColor: "#26323e",
};

export default config;

